window.addEventListener("DOMContentLoaded", function () {
  const card = document.querySelector("#card");

  function lookAt(x, y) {
    card.style.transform = `rotateY(${x}deg) rotateX(${y}deg)`;
  }

  let x = 0;
  let y = 0;

  function step() {
    x += 0.04;
    y += 0.02;

    lookAt(Math.sin(x) * 10, Math.cos(y) * 10);
    requestAnimationFrame(step);
  }

  requestAnimationFrame(step);
});
